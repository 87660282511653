import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TopPickUpItem from "./TopPickUpItem";
// NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
//import { useEnvironment } from "~/context/EnvironmentContext";

const TopPickUp = () => {
  // コンテキストから静的画像バケットのエンドポイントを取得
  // NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
  // const { staticImageBucketEndpoint } = useEnvironment();

  // NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
  // 画像URLを変数に格納（classNameで呼び出すため事前に変数に格納）
  //const pickupArrowImageLeftURL = `url(${staticImageBucketEndpoint}/public/top/pickup-arrow-left.webp)`;
  //const pickupArrowImageRightURL = `url(${staticImageBucketEndpoint}/public/top/pickup-arrow-right.webp)`;

  return (
    <section className="my-14 md:mt-20 md:mb-[104px]">
      <div className="px-4 md:px-7">
        <div className="max-w-[1040px] mx-auto">
          <div className="flex items-center sp:justify-center">
            <svg className="block w-5 h-5 mr-1 fill-primary md:w-6 md:h-6">
              <use xlinkHref="/symbol-defs.svg#icon-egg"></use>
            </svg>
            <p className="text-primary text-sm font-candal tracking-[0.06em] md:text-lg md:tracking-[0.06em]">
              Pick Up
            </p>
          </div>
          <h2 className="mb-3 text-2xl text-center font-black leading-normal md:text-left md:text-[32px] md:leading-normal">
            ピックアップ求人
          </h2>
        </div>
      </div>
      <div
        id="pickupSlider"
        className={`[&_.swiper-button-prev:after]:bg-[url(https://public.fjob-kyujin.com/public/top/pickup-arrow-left.webp)] [&_.swiper-button-next:after]:bg-[url(https://public.fjob-kyujin.com/public/top/pickup-arrow-right.webp)]`}
      >
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 16,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
        >
          {topPickUpItems.map((item, index) => (
            <SwiperSlide key={index}>
              <TopPickUpItem
                linkUrl={item.linkUrl}
                company={item.company}
                title={item.title}
                payment={item.payment}
                location={item.location}
                holiday={item.holiday}
              >
                {item.tags.map((tag) => (
                  <li key={tag}>{tag}</li>
                ))}
              </TopPickUpItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

const topPickUpItems = [
  {
    "linkUrl": "/detail/1f4ec08c60917eeec6cba95be3368aa66c10655959c6de68d3f7c0bfcce1ec75.html",
    "company": "株式会社木曽路",
    "title": "【年収525万円】渋谷区にある焼肉店「焼肉大将軍 笹塚店」にて店長候補募集！多業態展開中◎",
    "payment": "32.1〜35万円",
    "location": "東京都渋谷区",
    "holiday": "週休2日制（月9日～10日休み）他",
    "tags": ["店長", "焼肉/ホルモン焼き"]
  },
  {
    "linkUrl": "/detail/bc8436740f857f3f98826693efc5c9356abd011ae1566682687f18c473230989.html",
    "company": "株式会社バサラダイニング",
    "title": "【年収490万円】京都市右京区にある「ベーカリー+カフェ グレイスガーデン 京都五条店」の調理スタッフ募集中！賞与年2回◎",
    "payment": "24〜35万円",
    "location": "京都府京都市右京区",
    "holiday": "完全週休2日制　年間休日112日",
    "tags": ["キッチン", "カフェ（フルサービス）", "ベーカリー"]
  },
  {
    "linkUrl": "/detail/777ac9dad6e4907d54664ab5326ad1661b897f727c2568452a865531562ae4ca.html",
    "company": "株式会社フォンス",
    "title": "【年収480万円】渋谷の蕎麦ダイニング「酢重正之酢重Indigo」のホールスタッフ募集！",
    "payment": "28〜40万円",
    "location": "東京都渋谷区",
    "holiday": "月8～9日休み　他",
    "tags": ["ホール", "和食（割烹/懐石/会席）", "そば", "居酒屋"]
  },
  {
    "linkUrl": "/detail/581313e61b44ec043e054fff36cca137c2a149a1478278775b4f60bf49818cab.html",
    "company": "株式会社エーピーホールディングス",
    "title": "【年収450万円】港区の上場企業の居酒屋「鮨 無何有」で料理長候補募集中！アピアランスに裁量あり◎",
    "payment": "28万円",
    "location": "東京都港区",
    "holiday": "週休2日制（月8日、シフト制）他",
    "tags": ["料理長", "居酒屋", "すし（高単価・カウンター）"]
  },
  {
    "linkUrl": "/detail/eaea4a7c99f8ee105c7c0e9d1f6dc665a23e74b14dd783ee79767bd1c24f8e36.html",
    "company": "株式会社サンパーク",
    "title": "【年収480万円】【年収550万円】行徳のカフェ「高木珈琲」の店長募集！FC本部としての事業拡大につきキャリアアップの可能性大！",
    "payment": "32〜39万円",
    "location": "千葉県市川市",
    "holiday": "～週休2日制+7連休制度、他～",
    "tags": ["店長", "カフェ（フルサービス）"]
  },
  {
    "linkUrl": "/detail/cc1dd6f2bc498d0dfcefe315ac3670ea5fcfa6440b6480bf947214de731f88f9.html",
    "company": "株式会社キープ・ウィルダイニング",
    "title": "【年収450万円以上】町田エリアの西東京最大級の大型ルーフトップレストラン「STRI」の料理長候補の募集！",
    "payment": "40〜45万円",
    "location": "東京都町田市",
    "holiday": "・月8日休み（シフト制）",
    "tags": ["キッチン", "イタリア料理"]
  },
  {
    "linkUrl": "/detail/896f4c82b8b109bde827cdac164320a37ed88b237ac5f6edf0c70a70444381c6.html",
    "company": "株式会社ベイクルーズ",
    "title": "【深夜勤務無し】錦糸町のベーカリー「BOUL'ANGE錦糸町店」のパン製造スタッフ募集中！",
    "payment": "24.3万円",
    "location": "東京都墨田区",
    "holiday": "月間公休日数8日～9日他",
    "tags": ["キッチン", "ベーカリー", "カフェ（フルサービス）"]
  },
  {
    "linkUrl": "/detail/74fa94f7b537e12ab1d04a67c89d9c58f5105ad2f657eb1b7f73d242e3f7c76a.html",
    "company": "カフェ・カンパニー株式会社",
    "title": "【髭・ピアス・ネイル・タトゥーOK】大阪府にあるカフェ「WIRED CAFE ルクア大阪店」にて店長候補募集！",
    "payment": "25〜40万円",
    "location": "大阪府大阪市北区",
    "holiday": "シフト制月6～8休み　他",
    "tags": ["店長", "カフェ（フルサービス）"]
  },
  {
    "linkUrl": "/detail/d90a881db5f447b1d5e103ae98600b743a30206d5bafc234c87e74a54944f115.html",
    "company": "株式会社ギフトホールディングス",
    "title": "【年収600万円】渋谷区にあるラーメン店「元祖油堂 恵比寿西口店」にて店長候補募集中！全国多数展開中◎",
    "payment": "27〜45万円",
    "location": "東京都渋谷区",
    "holiday": "週休2日（シフト制）　他",
    "tags": ["店長", "ラーメン/つけ麺"]
  },
  {
    "linkUrl": "/detail/f5d3de8a6f275caebb828a5670b815603a59f06498e9573cf13883248b49bd4e.html",
    "company": "株式会社SASAYAホールディングス",
    "title": "【独立支援あり】大阪市中央区にある「備長炭やきとり SASAYA心斎橋本店」にて店長候補募集中！",
    "payment": "30万円",
    "location": "大阪府大阪市中央区",
    "holiday": "週休2日制／有給休暇／慶弔休暇",
    "tags": ["店長", "焼き鳥（高単価）", "居酒屋"]
  }
];

export default TopPickUp;
